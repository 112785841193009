// Fetch with timeout
export const fetchWithTimeOut = async (url, options, time) => {
  let timeout = time * 1000;
  return Promise.race([
    fetch(url, options),
    new Promise((_, reject) =>
      setTimeout(
        () =>
          reject(
            new Error('Take to long, Connection was reset. Please try again.')
          ),
        timeout
      )
    ),
  ]);
};

export const logsFunc = (act, id, name, logs) => {
  const log = {
    action: act,
    userId: id,
    username: name,
    time: new Date().toISOString(),
  };
  if (!logs) {
    return JSON.stringify([log]);
  } else {
    let prevLogs = JSON.parse(logs);
    return JSON.stringify([...prevLogs, log]);
  }
};
// Get Random Number
export const randomNumber = (min, max) => {
  return Math.floor(Math.random() * (max - min + 1)) + min;
};
export const convertSize = (val) => {
  let size = '';
  if (val < 1000000) {
    size = (Math.round(val / 10) / 100).toFixed(2) + ' KB';
  }
  if (val > 1000000 && val < 1000000000) {
    size = (Math.round(val / 10000) / 100).toFixed(2) + ' MB';
  }
  if (val > 1000000000 && val < 1000000000000) {
    size = (Math.round(val / 10000000) / 100).toFixed(2) + ' GB';
  }
  return size;
};
// Capacity Hosting
export const convertCapacity = (val1, val2) => {
  let size = {
    used: `0 MB`,
    usedPercent: (val1 / val2) * 100,
    total: val2 / 1000000000 + ' GB',
  };
  if (val1 < 1000000) {
    size.used = (Math.round(val1 / 10) / 100).toFixed(2) + ' KB';
  }
  if (val1 > 1000000 && val1 < 1000000000) {
    size.used = (Math.round(val1 / 10000) / 100).toFixed(2) + ' MB';
  }
  if (val1 > 1000000000 && val1 < 1000000000000) {
    size.used = (Math.round(val1 / 10000000) / 100).toFixed(2) + ' GB';
  }
  return size;
};

export const ALPHA_NUMBER =
  '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';

export const ALPHA_INDEX = [
  'a',
  'b',
  'c',
  'd',
  'e',
  'f',
  'g',
  'h',
  'i',
  'j',
  'k',
  'l',
  'm',
  'n',
  'o',
  'p',
  'q',
  'r',
  's',
  't',
  'u',
  'v',
  'w',
  'x',
  'y',
  'z',
];

export const useUnamePass = () => {
  let uPass = localStorage.getItem('unamePass');
  return uPass;
};

export const dateIsValid = (val) => {
  let dateStr = val.split('/').join('-');
  const regex = /^\d{4}-\d{2}-\d{2}$/;

  if (dateStr.match(regex) === null) {
    return false;
  }

  const date = new Date(dateStr);

  const timestamp = date.getTime();

  if (typeof timestamp !== 'number' || Number.isNaN(timestamp)) {
    // 👇️ this runs
    return false;
  }

  return date.toISOString().startsWith(dateStr);
};

export const timeIsValid = (val) => {
  var isValid = /^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$/.test(val);

  if (!isValid) {
    return false;
  }

  return true;
};
// trim left space of string
export const ltrim = (str) => {
  if (!str) return str;
  return str.replace(/^\s+/g, '');
};
// Round number 2 decimal
export const roundNumber = (val) => {
  return +(Math.round(val + 'e+2') + 'e-2');
};

export const getSession = (id) => {
  let data = sessionStorage.getItem(id);
  return data && JSON.parse(data);
};

export const setSession = (id, data) => {
  sessionStorage.setItem(id, JSON.stringify(data));
  return id;
};

export const removeSession = (id) => {
  sessionStorage.removeItem(id);
  return id;
};

export const isJsonString = (str) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};
